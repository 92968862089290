<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Tree view</h3>

	<p>The “tree view” is the baseline view for exploring {{framework_noun+'s'}} in <span v-html="app_name"/>. The framework’s document, {{comp_noun+'s'}}, and organizational elements are shown in a tree structure for you to browse and explore.</p>
	<p><img alt="Kebab menu image" srcset="/docimages/kebab_menu-1.png 4x" class="k-help-img float-right">To enter tree view, click the <span v-html="link('kebab_menu', '“kebab” menu')"></span> in the upper-right corner of the window and click TREE on the Viewer Mode toggle button.<img al="Treeview Viewer Mode image" srcset="/docimages/treeview-2.png 3x" class="k-help-img block"></p>

	<p><img alt="Treeview image" srcset="/docimages/treeview-1.png 2x" class="k-help-img"></p>

	<ol>
		<li>The item marked by the <v-icon small class="mx-1">fas fa-map</v-icon> icon is the <i>document</i> item for the framework. Click the icon or the document title to see the <span v-html="link('document_card', 'document’s <i>card</i>,')"></span> which includes metadata about the framework (e.g. its adoption status and when the framework was last changed).</li>
		<li>Items marked by the <v-icon small class="mx-1">fas fa-circle-chevron-right</v-icon> icon are “tree branches” that have “child” items. <ul>
			<li>Click the <v-icon small class="mx-1">fas fa-circle-chevron-right</v-icon> icon to reveal the item’s children. Click the parent item’s text to both reveal its children and see the item’s card, which includes additional information about the item.</li>
			<li>Click the item’s icon or text again to hide its children.</li>
			<li>Hold down the COMMAND (Mac)/ALT (Windows) key while you click a parent item to reveal or hide the children of both the item you clicked <i>and all its siblings</i>.</li>
			</ul>
		</li>
		<li>Items marked by the <v-icon small class="mx-1">fas fa-circle-dot</v-icon> icon are “leafs” in the tree. Click the icon or text of a leaf to see the <span v-html="link('item_card', 'item’s card')"></span>.</li>
	</ol>
	<ul>
		<li>Note that according to the CASE standard, and in this documentation, all {{comp_noun+'s'}} and organizational elements are referred to as <i>items</i>. Each item may be designated as a certain <i>item type</i> (e.g. “Standard,” “Element,” “Cluster,” “Course,” etc.). Item types are indicated in the tree structure by the background color of each item, and you can view an item’s type by clicking to view its card.</li>
		<li>Additional viewing options are available via the <span v-html="link('kebab_menu', 'kebab')"></span> <nobr>(<v-icon small class="mx-1">fas fa-ellipsis-v</v-icon>)</nobr> menu in the upper-right corner of the window.</li>
	</ul>
</div></template>

<script>
import HelpMixin from './HelpMixin'
export default {
	mixins: [HelpMixin],
}
</script>

<style lang="scss">
</style>
